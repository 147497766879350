import React, {useState, useEffect} from 'react';
import {AlertCircle} from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';

interface Celebration {
    id: string;
    event: string;
    datePretty: string;
    dateISO: string;
}

interface GameState {
    currentEvents: Celebration[];
    remainingEvents: Celebration[];
    nextEvent: Celebration | null;
    score: number;
    gameOver: boolean;
    message: string;
}

const TimelineGame: React.FC = () => {
    const initialGameState: GameState = {
        currentEvents: [],
        remainingEvents: [],
        nextEvent: null,
        score: 0,
        gameOver: false,
        message: '',
    };

    const [gameState, setGameState] = useState<GameState>(initialGameState);

    const apiHost = process.env.NODE_ENV === 'production' ? '' : 'http://127.0.0.1:5000';

    useEffect(() => {
        const initializeGame = async () => {
            try {
                const response = await fetch(`${apiHost}/api/celebrations`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: Celebration[] = await response.json();

                const startEvent = data[0];
                setGameState({
                    currentEvents: [startEvent],
                    remainingEvents: data.slice(1),
                    nextEvent: data[1],
                    score: 0,
                    gameOver: false,
                    message: '',
                });
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        };

        initializeGame();
    }, []);

    const handleGuess = (guessIndex: number) => {
        const {currentEvents, nextEvent, score, remainingEvents} = gameState;
        if (!nextEvent) return;

        let isCorrect: boolean;
        if (guessIndex === 0) {
            isCorrect = nextEvent.dateISO < currentEvents[0].dateISO;
        } else if (guessIndex === currentEvents.length) {
            isCorrect = nextEvent.dateISO > currentEvents[currentEvents.length - 1].dateISO;
        } else {
            isCorrect = currentEvents[guessIndex - 1].dateISO < nextEvent.dateISO && nextEvent.dateISO < currentEvents[guessIndex].dateISO;
        }

        if (isCorrect) {
            const updatedEvents = [
                ...currentEvents.slice(0, guessIndex),
                nextEvent,
                ...currentEvents.slice(guessIndex)
            ];
            const newRemainingEvents = remainingEvents.slice(1);
            const newNextEvent = newRemainingEvents[0] || null;
            setGameState({
                currentEvents: updatedEvents,
                remainingEvents: newRemainingEvents,
                nextEvent: newNextEvent,
                score: score + 1,
                gameOver: !newNextEvent,
                message: newNextEvent ? 'Correct! Keep going!' : `Congratulations! You've completed the timeline with a perfect score of ${score + 1}!`,
            });
        } else {
            const correctPosition = nextEvent.dateISO < currentEvents[0].dateISO
                ? "at the start"
                : nextEvent.dateISO > currentEvents[currentEvents.length - 1].dateISO
                    ? "at the end"
                    : `after "${currentEvents.find(event => event.dateISO > nextEvent.dateISO)?.event}"`;

            setGameState({
                ...gameState,
                gameOver: true,
                message: `Game Over! Your final score is ${score}. The correct position was ${correctPosition}.`,
            });
        }
    };

    const resetGame = async () => {
        setGameState(initialGameState);
        try {
            const response = await fetch(`${apiHost}/api/celebrations`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: Celebration[] = await response.json();
            const shuffled = data.sort(() => 0.5 - Math.random());
            const startEvent = shuffled[0];
            setGameState({
                currentEvents: [startEvent],
                remainingEvents: shuffled.slice(1),
                nextEvent: shuffled[1],
                score: 0,
                gameOver: false,
                message: '',
            });
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Timeline Game</h1>
            <p className="mb-4 text-lg">Score: {gameState.score}</p>
            {gameState.message && (
                <Dialog.Root>
                    <Dialog.Trigger className="mb-6">
                        <AlertCircle className="h-4 w-4"/>
                    </Dialog.Trigger>
                    <Dialog.Content>
                        <Dialog.Title>Game Status</Dialog.Title>
                        <Dialog.Description>{gameState.message}</Dialog.Description>
                    </Dialog.Content>
                </Dialog.Root>
            )}
            {!gameState.gameOver && gameState.nextEvent && (
                <div className="mb-8 bg-gradient-to-r from-blue-500 to-purple-500 p-6 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-3">Place this event:</h2>
                    <div className="bg-white p-4 rounded-md text-lg font-medium text-gray-800">
                        {gameState.nextEvent.event}
                    </div>
                </div>
            )}
            <div className="space-y-4 border-l-4 border-gray-300 pl-4">
                {!gameState.gameOver && gameState.nextEvent && (
                    <button
                        onClick={() => handleGuess(0)}
                        className="w-full py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors shadow-md"
                    >
                        Place event at the start
                    </button>
                )}
                {gameState.currentEvents.map((event, index) => (
                    <React.Fragment key={event.id}>
                        <div className="bg-gray-100 p-4 rounded-md shadow relative flex justify-between">
                            <div
                                className="absolute -left-6 top-1/2 transform -translate-y-1/2 w-4 h-4 bg-blue-500 rounded-full border-4 border-white"></div>
                            {event.event}
                            <span className="text-gray-600">{event.datePretty}</span>
                        </div>
                        {!gameState.gameOver && gameState.nextEvent && (
                            <button
                                onClick={() => handleGuess(index + 1)}
                                className="w-full py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors shadow-md"
                            >
                                Place event here
                            </button>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {gameState.gameOver && (
                <button
                    onClick={resetGame}
                    className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors shadow-md text-lg font-medium"
                >
                    Play Again
                </button>
            )}
        </div>
    );
};

export default TimelineGame;